.spinnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.resultsLoading {
        margin-top: 3rem;
    }

    p {
        margin-top: 0.25rem;
        font-size: 0.875rem;
        font-weight: 400;
    }
}

.spinner {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.spinner div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $blue;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $blue transparent transparent transparent;
}
.spinner div:nth-child(1) {
    animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
    animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ThreeDots {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1.5rem;
}

.ThreeDots div {
    height: 0.375rem;
    width: 0.375rem;
    display: block;
    border-radius: 50%;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.ThreeDots div:first-child {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.ThreeDots div:nth-child(2) {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.smallDots {
    width: 1rem;
}

.smallDots div {
    height: 0.25rem;
    width: 0.25rem;
}
