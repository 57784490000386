@import "../helpers/variables";
@import "../helpers/media-queries";
@import "../helpers/mixins";
@import "../layout/inputs";

.form {
    h2 {
        color: #006ca3;
        font-family: $heading-font;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 55px;
        margin-top: 2rem;
        margin-bottom: 1rem;
        @include for-tablet-up {
            margin-top: -6.5rem;
            margin-bottom: 2rem;
        }
    }

    .card {
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.02);
        padding: 3.4375rem;
        margin-bottom: 2rem;
        @include for-tablet-down {
            padding: 1.5rem;
        }

        &.split,
        .split {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include for-tablet-down {
                flex-direction: column-reverse;
            }

            > label,
            > div {
                @include for-tablet-up {
                    width: 48%;
                }
            }
        }

        &.split-3,
        .split-3 {
            @include for-tablet-up {
                display: flex;
                justify-content: space-between;
            }

            > label,
            > div {
                @include for-tablet-up {
                    width: 32%;
                }
            }
        }

        .image-cards {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            position: relative;
            //width: 100%;

            [type="radio"] {
                position: absolute;
                opacity: 0;
                &:hover {
                    cursor: pointer !important;
                }
            }

            [type="radio"] + img {
                cursor: pointer !important;
            }

            > div {
                border: 1px solid #e2e4e4;
                border-radius: 8px;
                padding: 1rem;
                margin-bottom: 1.5rem;
                width: 48%;
                position: relative;
                @include for-tablet-up {
                    width: 32%;
                }

                input {
                    height: 100%;
                    width: 100%;
                    padding: 0;
                    border: 1px solid #c5c5c5;
                }
                &:hover {
                    cursor: pointer !important;
                }
            }

            label {
                display: block;
                color: #7b7b7b;
                font-family: $body-font;
                font-weight: normal;
                font-size: 18px;
                letter-spacing: 0;
                line-height: 26px;
                padding-top: 1rem;
            }

            > .selected {
                border: 1px solid #006ca3;
                background-color: #e1eaf0;
                position: relative;

                label {
                    color: #00497b;
                }

                &::before {
                    content: "";
                    position: absolute;

                    top: 5px;
                    left: 5px;
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                    background: #006ca3 url("../../images/icons/checkmark.svg") no-repeat 50%;
                    background-size: 40%;
                }
            }

            &.four-cards {
                > div {
                    @include for-tablet-up {
                        width: 24%;
                    }
                }

                label {
                    font-size: 16px;
                }
            }
        }

        .home-direction {
            display: flex;
            justify-content: space-between;
            align-items: center;

            img {
                padding-top: 1rem;
                padding-right: 1rem;
            }
        }

        &.feedback {
            label {
                font-size: 18px;
                font-weight: normal;
            }

            .input-title {
                margin-bottom: 1rem;
                color: #3b3b3b;
            }

            p {
                color: #65676a;
            }
        }

        &.survey {
            .input-title {
                margin-top: 2rem;
                margin-bottom: 1rem;
                color: #3b3b3b;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .issue {
                font-size: 24px;
            }
        }

        .image-cards-radio {
            label {
                font-size: 18px;
            }
        }

        .fields-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2rem;
        }

        &.temp-selector {
            .header {
                @include for-tablet-up {
                    display: flex;
                    justify-content: space-between;
                }

                .toggle-radio {
                    &:not(.old-ie) {
                        display: flex;
                        width: 16rem;

                        label {
                            background: $white;
                            //padding: 0.25em 0;
                            display: block;
                            color: $primary-color;
                            width: 8rem;
                            float: left;
                            text-align: center;
                            font-size: 18px;
                            font-weight: normal;
                            border: 1px solid $primary-color;

                            &:hover {
                                cursor: pointer;
                                background: #d3d3d3;
                            }

                            &:last-child {
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                            }
                        }

                        [type="radio"] {
                            display: none;

                            &:first-child {
                                + {
                                    label {
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                        border-right: 0;
                                    }
                                }
                            }

                            &:checked {
                                + {
                                    label {
                                        background: $primary-color;
                                        color: $white;
                                        font-weight: 900;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .fields {
                @include for-tablet-up {
                    display: flex;
                    justify-content: space-between;
                }

                > div {
                    label {
                        font-size: 18px;

                        input {
                            margin-bottom: 2rem;

                            &:last-of-type {
                                margin-bottom: 1rem;
                            }

                            @include for-tablet-up {
                                margin-bottom: 0;
                            }
                        }
                    }
                    @include for-tablet-up {
                        width: 24%;
                        margin-bottom: 0;
                    }
                }

                &.field-rows {
                    align-items: center;
                    padding: 0.875rem 1rem;

                    &:nth-child(even) {
                        background-color: $grey-10;
                        border-radius: 0.5rem;
                    }

                    > div {
                        width: 20%;

                        p {
                            font-size: 18px;
                            font-weight: bold;
                        }

                        &:first-of-type {
                            width: 10%;
                        }

                        input {
                            margin: 0;
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }

        &.error {
            border-radius: 8px;
            border-color: #f48020;
            border-style: solid;
            border-width: 2px;
            border-left-width: 8px;

            input,
            textarea,
            select {
                border: 1px solid #f48020 !important;
            }
            .input-error {
                color: #f48020;
            }
        }
    }

    .screen-error-warning {
        margin-top: -1.25rem;
        margin-bottom: 1rem;
    }

    .screen-error-warning-bottom {
        margin-bottom: -1.25rem;
        margin-top: 1rem;
    }
}

@include for-tablet-up {
    .nudge {
        padding-left: 2.3rem;
    }
}
