select {
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    cursor: pointer;
    box-sizing: border-box;
    height: 3.125rem;
    width: 100%;
    border: 1px solid #e2e4e4;
    border-radius: 6px;
    color: $body-font-color;
    line-height: 1.1875rem;
    appearance: none;
    background-size: 0.625rem;
    min-width: 12rem;
    transition: color 0.3s ease;
    background: url("../../images/icons/chev-black.png") no-repeat right $white;
    -webkit-appearance: none;
    background-position-x: 95%;
    @include for-tablet-up {
        min-width: 18.625rem;
    }
    @include for-tablet-down {
        margin-bottom: 1rem;
    }
    &:hover,
    &:focus {
        color: $primary-color;
    }
    + span {
        border-radius: 0 1.5rem 1.5rem 0;
        margin-left: -2px;
        max-height: 3rem;
        cursor: pointer;
        &::after {
            display: none;
        }
    }
}
input,
input[type="email"],
input[type="text"],
input[type="number"],
textarea {
    box-sizing: border-box;
    height: 3.125rem;
    width: 100%;
    border: 1px solid #e2e4e4;
    border-radius: 6px;
    background-color: $white;
    font-size: 18px;
    padding: 1rem;
}
textarea {
    padding: 1rem;
    margin: 1rem 0 2rem;
    height: 6.25rem;
    min-height: 6.25rem;
    max-height: 20rem;
    resize: vertical;
}

label,
.input-title {
    color: #3b3b3b;
    font-family: $body-font;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    margin-bottom: 2rem;
    input,
    textarea {
        margin-top: 1rem;
    }
    @include for-tablet-up {
        font-size: 24px;
    }
}

[type="checkbox"],
[type="radio"] {
    height: 20px;
    width: 20px;
    vertical-align: middle;
    position: relative;
    bottom: 1px;
    cursor: pointer;

    & + label {
        cursor: pointer;
        padding-left: 1rem;
    }
}

.input-container {
    color: #3b3b3b;
    font-family: $body-font;
    font-size: 16px;
    width: 100%;
    label {
        font-size: 16px;
    }
    &.radiocheck-split {
        display: flex;
        > div {
            margin-right: 4rem;
        }
    }
    &.home-size-field {
        position: relative;
        label {
            display: inline-block;
        }
        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            @include for-tablet-up {
                width: 25rem;
            }
        }
        select {
            position: absolute;
            height: 3.125rem !important;
            top: 0;
            left: 25rem;
            width: 6rem;
            min-width: 3rem;
            background-position-x: 85%;
            border-left: none;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }
}

label {
    span {
        font-weight: normal;
    }
}

.image-example {
    p {
        font-size: 0.875rem;
        margin-top: 0.5rem;
    }
}
