//==================================================================
// COLOR VARIABLES
//=================================================================
/*==== BRAND COLORS ====*/
$orange: #f48020;
$yellow: #fdb813;
$green: #c3cf21;
$blue: #006ca3;
$light-blue: #6ecff6;
$dark-blue: #00497b;
$placeholderBlue: #bedded;
$font-grey: #7b7b7b;

$white: #ffffff;
$black: #000000;
$grey-10: #f6f6f6;
$grey-20: #d7d7d7;
$grey-30: #a8a8a8;
$grey-50: #39393c;

$error-color: #ee2e24;

$primary-color: $blue;
$secondary-color: $yellow;
$body-font-color: $grey-50;

$colorList: (
    yellow: $yellow,
    green: $green,
    blue: $blue,
    white: $white,
    black: $black,
    grey-50: $grey-50,
);

//==================================================================
// TYPOGRAPHY VARIABLES
//==================================================================
$heading-font: "Overpass", sans-serif;
$body-font: "Noto Sans TC", sans-serif;
$base-font-size: 16px;
$heading-config: (
    h1: (
        mobile: (
            font-size: 2.5rem,
            line-height: 1.1,
            font-weight: 600,
            letter-spacing: -1.39px,
            color: $primary-color,
        ),
        desktop: (
            font-size: 3.75rem,
            line-height: 1.03,
        ),
    ),
    h2: (
        mobile: (
            font-size: 2rem,
            line-height: 0.9375,
            font-weight: 300,
        ),
        desktop: (
            font-size: 2.5rem,
            line-height: 1.125,
        ),
    ),
    h3: (
        mobile: (
            font-size: 1.5rem,
            line-height: 1,
            font-weight: 600,
        ),
        desktop: (
            font-size: 1.5rem,
            line-height: 1.33,
        ),
    ),
);
//==================================================================
// LAYOUT VARIABLES
//==================================================================
$box-shadow: 0 0 7px 0 rgba(77, 77, 77, 0.11);

//==================================================================
// BREAKPOINT VARIABLES
//==================================================================
$phone-breakpoint: 30rem;
$tablet-breakpoint: 48rem;
$laptop-breakpoint: 60rem;
$desktop-breakpoint: 68.5625rem;
