//==================================================================
// 1. HELPERS
//==================================================================
@import "styles/helpers/normalize";
@import "styles/helpers/variables";
@import "styles/helpers/media-queries";
@import "styles/helpers/mixins";

//==================================================================
// 2. LAYOUT
//==================================================================
@import "styles/layout/base";
@import "styles/layout/container";
@import "styles/layout/typography";
@import "styles/layout/buttons";
@import "styles/layout/inputs";
@import "styles/layout/loading";
@import "styles/layout/warning";

header {
    height: 8rem;
    width: 100%;
    background: linear-gradient(129.09deg, #00497b 0%, #006ca3 100%);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    z-index: 3;

    @include for-tablet-up {
        height: 10rem;
    }

    .container {
        .logo-nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include for-tablet-down {
                background: $blue;
                margin-top: -1rem;
                height: 75px;
                padding: 0 0 0 1rem;
            }
        }

        .mobileIntro {
            display: none;
        }

        @include for-tablet-down {
            .mobileIntro {
                display: block;

                h1 {
                    color: $white;
                    font-family: $heading-font;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: -0.75px;
                    line-height: 25px;
                    z-index: 2;
                    width: 82%;
                    padding-left: 1rem;
                    text-align: left;
                    border-left: 1px solid rgba(255, 255, 255, 0.25);
                }

                @include for-tablet-down {
                    padding: 0 1rem;
                }
            }
        }
    }

    .logo {
        img {
            height: 2rem;
        }
    }

    nav {
        display: none;
        @include for-tablet-up {
            display: block;
            ul {
                li {
                    display: inline;

                    &:before {
                        display: none;
                    }

                    a {
                        color: #fff;
                        font-family: $heading-font;
                    }

                    &:not(:last-child) {
                        padding-right: 3rem;
                    }
                }
            }
        }
    }

    .hero {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .heading {
            //background: $blue;

            .intro {
                @include for-tablet-down {
                    display: none;
                }
                width: 46.5%;
                @include for-desktop-up {
                    width: 50%;
                }
                height: 60px;
                position: absolute;
                left: 0;
                top: 50%;
                background: $blue;
                z-index: 2;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background: $blue;
                    transform-origin: 100% 0;
                    transform: skewX(25deg);
                    z-index: 1;
                }

                h1 {
                    position: absolute;
                    color: $white;
                    font-family: $heading-font;
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: -0.75px;
                    line-height: 45px;
                    z-index: 2;
                    text-align: right;
                    width: 82%;
                }
            }
        }
    }
}

.app-body {
    padding: 1rem 1rem;
    @include for-tablet-up {
        padding: 3rem 0;
    }
}

.results-page {
    header {
        @include for-tablet-down {
            height: 4.5rem;
        }
        .logo-nav {
            @include for-tablet-up {
                margin-bottom: 3rem;
            }
            @include for-tablet-down {
                margin-top: 0;
            }
        }
    }
}
