.warning {
    background-color: rgba($orange, 0.2);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;

    p {
        font-weight: 600;
        font-size: 0.875rem;
    }

    .warning-exclamation {
        background-color: $orange;
        color: $white;
        border-radius: 0.375rem;
        flex-shrink: 0;
        padding: 0.125rem 0 0;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        font-weight: 800;
        margin-right: 0.5rem;
    }
}
