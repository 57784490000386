//==================================================================
// BASE PAGE LAYOUT / GENERIC STYLES
//==================================================================
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

body {
    background-color: #f6f6f6;
    padding: 0;
    overflow-x: hidden;
    color: $body-font-color;
    font-family: $body-font;
    font-size: 16px;
    letter-spacing: 0;
    @include for-tablet-up {
        overflow-y: hidden;
        .WIZARD_CLASS {
            padding: 4rem 0;
        }
    }
}

.skip-link {
    position: absolute;
    top: 2.5rem;
    left: 1rem;
    transform: translateX(-100vw);
    transition: transform 0.3s ease;
    z-index: 4;
    @include for-laptop-up {
        top: 0.5rem;
    }
    &:focus {
        transform: translateX(0);
        margin-top: 0.35rem;
        background: white;
        padding: 1rem;
        border-radius: 4px;
        font-weight: 900;
    }
}

#mainSpacer {
    overflow-x: hidden;
}

img {
    height: auto;
    max-width: 100%;
}

html.noscroll {
    overflow: hidden;
    &.overlay {
        position: relative;
    }
}

//==================================================================
// LAYOUT UTILITY
//==================================================================
.invisible {
    @include invisible;
}

.bg-image {
    @include bg-image;
}
