@import "../helpers/variables";
@import "../helpers/media-queries";

.stepper {
    list-style: none;
    position: relative;

    margin-right: 0.5rem;
    @include for-tablet-up {
        margin: 0;
    }

    li {
        display: inline-block;
        margin: 0 0.5rem;
        @include for-tablet-up {
            margin: 0 1rem;
        }

        a {
            padding-top: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            height: 32px;
            width: 32px;
            border: 1px solid rgba($white, 0.5);
            border-radius: 4px;
            background-color: $blue;
            font-size: 20px;
            letter-spacing: 0;
            color: rgba($white, 0.5);
            font-family: $heading-font;
            z-index: 5;
            position: relative;
            cursor: default;
            pointer-events: none;
            transition: background-color 0.3s ease-in-out;

            @include for-tablet-up {
                border-radius: 8px;
                height: 44px;
                width: 44px;
            }
        }

        &.validated {
            a {
                border: 1px solid $white;
                color: $white;

                &:hover,
                &:focus {
                    background-color: $dark-blue;
                }
            }
        }

        &.active {
            a {
                border: 1px solid $white;
                background-color: $white;
                color: $blue;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: 10%;
            top: 50%;
            width: 80%;
            height: 100%;
            border-style: solid;
            border-color: $white;
            border-top-width: 1px;
            z-index: 1;
        }
    }
}
