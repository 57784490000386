//=================================================================
// MIXINS
//=================================================================
@mixin link-underline {
    position: relative;
    padding-bottom: .25rem;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scale(0);
        transform-origin: 0;
        height: 2px;
        background-color: $yellow;
        bottom: 0;
        left: 0;
        transition: transform .6s ease;
    }
    &:hover, &:focus {
        &::after {
            transform: scale(1);
        }
    }
}

//=================================================================
/* UTILITY MIXINS
//=================================================================
/* ---- invisible ---- */
@mixin invisible {
    position: absolute;
    opacity: 0;
    line-height: 0;
    height: 0;
    width: 0;
    overflow: hidden;
}

/* ---- bg-image ---- */
@mixin bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* normalize lists */
@mixin normalize {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
        padding-left: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        line-height: 1;
        &::before {
            display: none;
        }
        a {
            border: 0;
        }
    }
}

/* ---- get values function ---- */
@function map-get-values($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}