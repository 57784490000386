//==================================================================
// TYPOGRAPHY
//==================================================================
html {
    font-size: $base-font-size;
    font-family: $body-font;
    color: $body-font-color;
    @include for-laptop-up {
        overflow-x: hidden;
    }
}

body,
::before,
::after {
    -webkit-font-smoothing: antialiased;
}

@each $heading-type, $breakpoint in $heading-config {
    #{$heading-type},
    .#{$heading-type} {
        margin: 0;
        @each $property in map-get-values($heading-config, "#{$heading-type}", "mobile") {
            $key: nth($property, 1);
            $value: nth($property, 2);
            #{$key}: #{$value};
        }
        @include for-laptop-up {
            @each $property in map-get-values($heading-config, "#{$heading-type}", "desktop") {
                $key: nth($property, 1);
                $value: nth($property, 2);
                #{$key}: #{$value};
            }
        }
    }
}

a {
    text-decoration: none;
    color: $primary-color;
    &.link-underline {
        @include link-underline;
    }
}

p,
li {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: normal;
    a {
        border-bottom: 1px solid transparent;
        color: $primary-color;
        transition: border-color 0.3s ease;
        &:hover,
        &:focus {
            border-color: $secondary-color;
        }
    }
}
ol {
    margin-left: 1rem;
    li {
        padding-left: 0.5rem;
        padding-bottom: 0.25rem;
    }
}
ul {
    list-style: none;
    margin-left: 0;
    li {
        position: relative;
        padding-left: 1rem;
        padding-bottom: 0.25rem;
        &::before {
            content: "\2022";
            color: $primary-color;
            font-weight: bold;
            font-size: 1.125rem;
            line-height: 1.625rem;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
.normalize {
    @include normalize;
}

//==================================================================
// TYPOGRAPHY CLASSES
//==================================================================
@each $colorKey, $color in $colorList {
    // To use: apply class (ex) '.text-brand-blue' to apply that color.
    .text-#{$colorKey} {
        color: $color;
    }
}

.intro-text {
    color: $light-blue;
    font-size: 0.8125rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
}

h1 {
    color: #006ca3;
    font-family: $heading-font;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 55px;
}
