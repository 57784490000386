.nav-steps {
    display: flex;
    justify-content: flex-end;
    .hidden {
        visibility: hidden;
    }
    button {
        z-index: 2;
    }
}
