@import "../helpers/variables";
@import "../helpers/media-queries";

.resultsHeading {
    h1 {
        margin-bottom: 1rem;
        @include for-tablet-up {
            margin-bottom: 0.5rem;
        }
    }
    .connectBtn {
        width: 100%;
        margin-top: 2rem;
        @include for-tablet-up {
            width: 14rem;
        }
    }
    @include for-tablet-up {
        display: flex;
        justify-content: space-between;
    }
}

.resultsCards {
    margin: 3rem 0;
    @include for-tablet-up {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .card {
        border-radius: 8px;
        background-color: $white;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.02);
        margin-bottom: 2rem;
        padding: 2rem;

        @include for-tablet-up {
            width: 31.5%;
        }

        &.chart-card {
            padding: 2rem 1rem 2rem 2rem;
        }

        h2 {
            color: #3b3b3b;
            font-family: $body-font;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 26px;
        }
        .details {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;

            h3 {
                margin-bottom: 0.25rem;
            }

            p {
                font-size: 0.875rem;
            }

            .left,
            .right {
                h3 {
                    color: #006ca3;
                    font-family: $body-font;
                    font-size: 21px;
                    font-weight: bold;
                    letter-spacing: 0;
                    line-height: 24px;
                }
                p {
                    color: $font-grey;
                }
            }
            .right {
                h3 {
                    color: $font-grey;
                }
            }
        }
        .chart {
            // Hack to fix Google Chart flickering bug
            svg > g > g:last-child {
                pointer-events: none;
            }
        }
    }
}

.resultsAccordion {
    border-radius: 8px;
    background-color: $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.02);
    margin-bottom: 2rem;
    padding: 2rem 1rem 2rem 2rem;

    .resultsIntro {
        @include for-tablet-down {
            margin-bottom: 2rem;
        }
        h2 {
            color: #3b3b3b;
            font-family: $body-font;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 35px;
            padding-bottom: 1rem;
        }
    }

    .tableHeadings {
        @include for-tablet-down {
            display: none;
            margin-bottom: 2rem;
        }

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 2rem 0 1rem;
        div {
            color: #3b3b3b;
            font-family: $body-font;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 21px;
        }
        div {
            width: 16.66%;
            &:first-of-type {
                width: 22%;
            }
        }
    }

    details {
        box-sizing: border-box;
        min-height: 50px;
        height: 100%;
        width: 100%;
        border: 1px solid #e2e4e4;
        border-radius: 8px;
        margin-bottom: 0.25rem;

        &:hover {
            border: 1px solid #006ca3;
        }
    }

    summary {
        list-style: none;
        color: #39393c;
        font-family: $body-font;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 26px;
        padding: 1rem;
        cursor: pointer;

        .headingMobile {
            display: block;

            @include for-tablet-up {
                display: none;
            }

            .name {
                font-size: 18px;
            }

            .results {
                display: flex;
                justify-content: space-between;
                padding-top: 0.5rem;

                div {
                    &.result {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;

                        .up {
                            color: #d95328;
                        }
                        .down {
                            color: #22a495;
                        }
                        .neutral {
                        }
                        .value {
                            height: 20px;
                            width: 60px;
                            font-family: $body-font;
                            font-size: 18px;
                            letter-spacing: 0;
                            line-height: 20px;
                            padding-left: 0.25rem;
                        }
                    }
                }
                .cost {
                    div:first-of-type {
                        font-size: 18px;
                        color: #006ca3;
                        font-weight: 900;
                    }
                }
            }
        }

        .heading {
            display: none;
            font-size: 18px;

            @include for-tablet-up {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                cursor: pointer;
            }

            div {
                width: 100%;
                @include for-tablet-up {
                    width: 22%;
                }

                &.result {
                    display: inline-flex;
                    width: 24%;
                    @include for-tablet-up {
                        width: 16.66%;
                        display: flex;
                        align-items: center;
                    }
                    .up {
                        width: 24px;
                        height: 24px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url("../../images/icons/up.svg");
                    }
                    .down {
                        width: 24px;
                        height: 24px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url("../../images/icons/down.svg");
                    }
                    .neutral {
                        width: 24px;
                        height: 24px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url("../../images/icons/neutral.svg");
                    }
                    .value {
                        padding-left: 1rem;
                    }
                }
            }
            .cost {
                color: #006ca3;
                font-weight: 900;
            }
        }
        &::-webkit-details-marker {
            display: none;
        }
    }

    .detailsBody {
        padding: 0 1rem 2rem;
    }

    details[open] {
        border: 1px solid #006ca3;

        .heading {
            .name {
                font-weight: 900;
            }
        }
        p {
            padding-bottom: 1rem;
            strong {
                display: block;
            }
            ul {
                list-style: none;
                li {
                    list-style: none;
                    a {
                        border-bottom-color: $primary-color;
                        &:hover,
                        &:focus {
                            font-weight: 900;
                        }
                    }
                }
            }
        }
    }

    details[open] summary {
        margin-bottom: 1rem;
    }
}
