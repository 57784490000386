button,
.btn {
    background-color: $primary-color;
    color: $white;
    letter-spacing: 0.1px;
    line-height: 1.625rem;
    font-size: 1.25rem;
    font-weight: 600;
    height: 40px;
    width: 123px;
    border-radius: 9px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.5rem;
        height: 0.6875rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: transform 0.5s ease;
    }
    &:hover,
    &:focus {
        background-color: $dark-blue;
        cursor: pointer;
        &::after {
            transform: translateY(-50%) translateX(0.325rem);
        }
    }
    &-yellow {
        background-color: $yellow;
        color: $black;
        padding: 0.6875rem 1.875rem 0.8125rem;
        &::after {
            display: none;
        }
        &:hover,
        &:focus {
            background-color: darken($yellow, 5%);
        }
    }
    &-sm {
        font-size: 0.8rem;
        line-height: 1.25rem;
        padding: 0.625rem 2.0625rem;
        &::after {
            display: none;
        }
    }
    &-next,
    &-submit {
        justify-content: normal;
        background: $primary-color url("../../images/icons/chev-white.svg") 80% no-repeat;
        span {
            margin-left: 2rem;
        }
        &:hover,
        &:focus {
            background: $dark-blue url("../../images/icons/chev-white.svg") 80% no-repeat;
        }
    }
    &-prev {
        height: 40px;
        width: 40px;
        border-radius: 9px;
        background: #e1eaf0 url("../../images/icons/chev-blue.svg") center no-repeat;
        margin-right: 1.5rem;
        &:hover,
        &:focus {
            background: $dark-blue url("../../images/icons/chev-white.svg") center no-repeat;
            transform: rotate(180deg);
        }
    }
    &-submit {
        justify-content: normal;
        background: $primary-color url("../../images/icons/chev-white.svg") 90% no-repeat;
        span {
            margin-left: 1.25rem;
        }
        &:hover,
        &:focus {
            background: $dark-blue url("../../images/icons/chev-white.svg") 90% no-repeat;
        }
    }
}
