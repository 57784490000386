//==================================================================
// CONTAINER
//==================================================================
$container-xs-width: 53.375rem;
$container-width: 69.5rem;
$container-width-lg: 72.875rem;
.container {
    width: 100%;
    max-width: ($container-width + (1rem*2));
    margin-left: auto;
    margin-right: auto;
    //padding-left: 1rem;
    //padding-right: 1rem;
    &-xs {
        max-width: ($container-xs-width + (1rem*2));
    }
    &-lg {
        max-width: ($container-width-lg + (1rem*2));
    }
}
